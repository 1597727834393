const body = document.querySelector("body");
let calc;
let modal;
let cancel;
let confirm;

const createCalc = () => {
  calc = document.createElement("div");
  calc.classList.add("calc");
};

const createModal = (message, confirmButton, cancelButton) => {
  modal = document.createElement("div");
  modal.classList.add("modal");
  modal.innerHTML = `<p>${message}</p>`;
  modal.addEventListener("click", (event) => {
    event.stopPropagation();
  });
  if (cancelButton) {
    cancel = document.createElement("button");
    cancel.innerText = cancelButton;
    cancel.classList.add("btn", "btn-secondary");
    modal.appendChild(cancel);
  }
  if (confirmButton) {
    confirm = document.createElement("button");
    confirm.innerText = "Confirmer";
    confirm.classList.add("btn", "btn-primary");
    modal.appendChild(confirm);
  }
};

export function openModal(message, confirmButton, cancelButton) {
  console.log("open Modal");
  createCalc();
  createModal(message, confirmButton, cancelButton);
  calc.append(modal);
  body.append(calc);

  return new Promise((resolve, reject) => {
    calc.addEventListener("click", () => {
      resolve(false);
      calc.remove();
    });

    if (cancel !== undefined) {
      cancel.addEventListener("click", () => {
        resolve(false);
        calc.remove();
      });
    }

    if (confirm !== undefined) {
      confirm.addEventListener("click", () => {
        resolve(true);
        calc.remove();
      });
    }
  });
}
